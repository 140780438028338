import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const Landingpage = () => import('@/views/HomePage.vue');
const ProjectDetail = () => import('@/views/ProjectView.vue');
const Rechtliches = () => import('@/views/legalPage.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Landingpage,
    props: {
      scrollContact : {type:String, required: false, default: ""},
      popupSelect: {type:String, required: false, default: ""}
    }
  },
  {
    path: '/projects/:url',
    name: 'projects',
    component: ProjectDetail,
    props: {
      url: {type:String, required: true}
    }
  },
  {
    path: '/rechtlich/:url',
    name: 'legalpage',
    component: Rechtliches,
    props: {
      url: {type:String, required: true}
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
     return savedPosition;
    }else{
      return { top: 0 }
    }
  }
});

export default router
