import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_mod = _resolveComponent("footer-mod")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, {
      cmsObject: _ctx.cmsHandle,
      scrollHandle: _ctx.scrollTrigger,
      class: _normalizeClass(["view", _ctx.$route.name])
    }, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    }, 8, ["cmsObject", "scrollHandle", "class"]),
    _createVNode(_component_footer_mod, {
      "headline-data": _ctx.textContent,
      "legal-array": _ctx.legalLinks
    }, null, 8, ["headline-data", "legal-array"])
  ], 64))
}