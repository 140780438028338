interface scrollInst {
    domElm : HTMLElement,
    animFunc : Function,
    stateInt : number
}
import gsap from "gsap";

class scrollTrigger {

    constructor() {
        window.addEventListener("scroll", () => {
            this.checkScroll();
        });
    }

    private relOffset(dom: HTMLElement){
        var viewportOffset = dom.getBoundingClientRect();

        var offset = {
            x: viewportOffset.left,
            y: viewportOffset.top
        }

        return offset.y;
    }

    private domList : Array<scrollInst> = [];

    public checkScroll(){
        const screenHeight = window.innerHeight;

        for(var x in this.domList){
            const entry = this.domList[x];
            const relOffTop = this.relOffset(entry.domElm);

            if(relOffTop - (screenHeight*0.75) <= 1){
                if(entry.stateInt < 1){
                    entry.stateInt = 1;
                    entry.animFunc(true, entry.domElm);
                }
            }else{
                if(entry.stateInt > -1){
                    entry.stateInt = -1;
                    entry.animFunc(false, entry.domElm);
                }
            }
        }
    }

    public addDomWatch(domStr:string, callback: Function){

        var classFinds = document.getElementsByClassName(domStr);
        if(!classFinds || classFinds.length === 0) return;

        this.domList.push({
            domElm : classFinds[0],
            animFunc: callback,
            stateInt : 0
        } as scrollInst);

        this.checkScroll();
    }

    public removeDomWatch(callback: Function){
        for(var x in this.domList){
            const entry = this.domList[x];
            if(entry.animFunc === callback){
                this.domList.splice(parseInt(x), 1);
            }
        }
    }
}

export default scrollTrigger;