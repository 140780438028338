
import {defineComponent, PropType} from "vue";
import {IHeadline} from "@/views/HomePage.vue";
import pageCMS, {cmsSearch, cmsSetting, param} from "@/modules/cmsRest";
import cmsRest from "@/modules/cmsRest";

export interface ILegalLink {
  title: string,
  url: string
}

export default defineComponent({
  name: "FooterMod",
  props: {
    headlineData: {
      type: Object as PropType<IHeadline>,
      required: true
    },
    legalArray: {
      type: Array as PropType<ILegalLink[]>,
      required: true
    }
  },
  data() {
    return{
    }
  },
  mounted(){},
  methods:{
    goToLegal(url: string): void {
      this.$router.push({name:"legalpage", params:{url:url}});
    }
  }
})
