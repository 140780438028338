
import {defineComponent} from "vue";
import pageCMS, {cmsSearch, cmsSetting, param} from "@/modules/cmsRest"
import scrollTrigger from "@/modules/scrollTrigger";
import {ILegalLink} from "@/components/FooterMod.vue";
import {IHeadline} from "@/views/HomePage.vue";
import FooterMod from "@/components/FooterMod.vue";

export default defineComponent({
  components: {FooterMod},
  data(){
    return{
      cmsHandle: new pageCMS(),
      scrollTrigger: new scrollTrigger(),
      textContent : {
        headline:"",
        subline:""
      } as IHeadline,
      legalLinks : [] as Array<ILegalLink>,
    }
  },
  mounted(){
    var CmsLoad = new pageCMS();
    CmsLoad.init().then(() => {
      this.cmsHandle = CmsLoad;

      this.requestLegal();
    });
  },
  methods:{
    requestLegal() {
      const cms = this.cmsHandle;

      cms.request("codespace_legal", true, {
        select: ["page_title", "url"]
      }).then((res) => {
        for (var n in res.entries) {
          this.legalLinks.push({
            title: res.entries[n].page_title,
            url: res.entries[n].url
          });
        }
      }).catch((err) => {
        console.error(err);
      });

      cms.request("hp_codespace", false, {
        select: ["footerMsg"]
      })
      .then((response) => {
        this.textContent = response.footerMsg;
      }).catch((err) => {
        console.log(err);
      });

    },
  }
})
